import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql, Link } from 'gatsby'

const items = [
  {
    link: '/agriculture/utility-tractors/',
    imageId: 'utilityTractorsImage',
    name: 'Utility Tractors',
    alt: 'John Deere Utility Tractors',
  },
  {
    link: '/agriculture/row-crop-tractors/',
    imageId: 'rowCropTractorsImage',
    name: 'Row Crop Tractors',
    alt: 'John Deere Row Crop Tractors',
  },
  {
    link: '/agriculture/harvesting/',
    imageId: 'harvestingImage',
    name: 'Harvesting',
    alt: 'John Deere Harvesting Equipment',
  },
  {
    link: '/agriculture/sprayers/',
    imageId: 'sprayersImage',
    name: 'Sprayers',
    alt: 'John Deere and Hagie Sprayers',
  },
  {
    link: '/agriculture/hay-forage/',
    imageId: 'hayForageImage',
    name: 'Hay and Forage',
    alt: 'John Deere Hay and Forage Equipment',
  },
  {
    link: '/agriculture/cutters-shredders/',
    imageId: 'rotaryCuttersImage',
    name: 'Cutters and Shredders',
    alt: 'John Deere Cutters and Shredders',
  },
  {
    link: '/agriculture/planting/',
    imageId: 'plantingImage',
    name: 'Planting',
    alt: 'John Deere Planing Equipment',
  },
  {
    link: '/agriculture/tillage/',
    imageId: 'tillageImage',
    name: 'Tillage',
    alt: 'John Deere Tillage Equipment',
  },
  {
    link: '/precision-upgrades/',
    imageId: 'puksImage',
    name: 'Precision Upgrades',
    alt: 'John Deere Precision Upgrades',
  },
]

const breadcrumbsSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': [
    {
      '@type': 'ListItem',
      'position': 1,
      'name': 'Hutson Inc',
      'item': 'https://www.hutsoninc.com/',
    },
    {
      '@type': 'ListItem',
      'position': 2,
      'name': 'Agriculture',
      'item': 'https://www.hutsoninc.com/agriculture/',
    },
  ],
})

const AgriculturePage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Agriculture Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson carries a variety of ag equipment, including row crop tractors, utility tractors, planters, sprayers, combines, hay and forage tools, and tillage tools.'
        />
        <meta
          name='keywords'
          content='agriculture, ag, John Deere, farming, equipment, row crop tractors, utility tractors, combines, sprayers, hay and forage tools, plantes, tillage tools'
        />
        <script type='application/ld+json'>{breadcrumbsSchema}</script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Agriculture Equipment' />

      <Content>
        <Floater items={gridItems} />

        <Copy header='John Deere Agriculture Equipment'>
          <p>
            For over a hundred years, John Deere has been an innovative factor in the agriculture
            community. And for almost a hundred years, Hutson has been supplying farmers with what
            they need to get the job done right. A pretty solid partenership you might say. Hutson
            carries a variety of agriculture equipment, including,{' '}
            <Link to='/agriculture/row-crop-tractors/'>row crop tractors</Link>,{' '}
            <Link to='/agriculture/utility-tractors/'>utility tractors</Link>,{' '}
            <Link to='/agriculture/harvesting/'>combines</Link>,{' '}
            <Link to='/agriculture/sprayers/'>sprayers</Link>,{' '}
            <Link to='/agriculture/hay-forage/'>hay and forage tools</Link>,{' '}
            <Link to='/agriculture/planting/'>planters</Link>,{' '}
            <Link to='/agriculture/tillage/'>tillage tools</Link>, and more. For more information
            about any of John Deere products,{' '}
            <Link to='/locations/'>contact one of our locations</Link>.
          </p>
        </Copy>

        <Promos data={promos} type='Agriculture Equipment' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query agricultureCategoryQuery($category: String = "agriculture") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "agriculture/agriculture-bg.jpg" }) {
      ...FullWidthImage
    }
    utilityTractorsImage: file(relativePath: { eq: "agriculture/6-family.jpg" }) {
      ...FloatingGridImage
    }
    rowCropTractorsImage: file(relativePath: { eq: "agriculture/row-crop.jpg" }) {
      ...FloatingGridImage
    }
    harvestingImage: file(relativePath: { eq: "agriculture/s-series.jpg" }) {
      ...FloatingGridImage
    }
    sprayersImage: file(relativePath: { eq: "agriculture/john-deere-sprayers.jpg" }) {
      ...FloatingGridImage
    }
    hayForageImage: file(relativePath: { eq: "agriculture/0-series-balers.jpg" }) {
      ...FloatingGridImage
    }
    plantingImage: file(relativePath: { eq: "agriculture/planting.jpg" }) {
      ...FloatingGridImage
    }
    tillageImage: file(relativePath: { eq: "agriculture/tillage.jpg" }) {
      ...FloatingGridImage
    }
    rotaryCuttersImage: file(relativePath: { eq: "cutters-shredders/rotary-cutter.jpg" }) {
      ...FloatingGridImage
    }
    puksImage: file(
      relativePath: { eq: "precision-upgrades/precision-upgrades-thumbnail-r3c006698_NK.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default AgriculturePage
